












































































































































































































import Vue from "vue";
import { Product } from "@/core/models";
import { Component, Prop, PropSync } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { paymentSettings } from "@/settings";

@Component
export default class PricingTier extends Vue {
  @Prop({ default: () => 0 }) i!: number;
  @Prop({ default: () => null }) item!: Product;
  @Prop({ default: 0 }) price!: number;
  @PropSync("isYearlyProp", {}) isYearly!: boolean;
  @Getter("profile/customerId") customerId!: string;
  @Getter("profile/id") userId!: string;

  get grossAmount() {
    const price = !this.isYearly
      ? this.item.unitAmountMonthly
      : this.item.unitAmountYearly;

    return paymentSettings.vatIncluded
      ? price
      : price + (price / 100) * paymentSettings.defaultVatRate;
  }

  formatPrice(amount: number) {
    return `€${amount.toFixed(2)}`;
  }

  formatAmount(amount: number) {
    // Use on all calculations with currency amounts per value to prevent 3rd decimal (.xx5) rounding issues of percent/tax calculations
    return parseFloat((amount / 100).toFixed(2));
  }

  removePrefix = (string: string, prefix: string) => {
    return string.replace(prefix, "");
  };
}
